import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  subs: Subscription[] = [];
  error: string|undefined;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.subs.push(this.route.queryParams.subscribe(params => {
      if (params?.token) {
        localStorage.setItem('token', params['token']);
        this.router.navigate(['/dashboard']);
      }
      if (params?.error) {
        this.error = `Something went wrong: ${params?.error}`;
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
