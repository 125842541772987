import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { JwtGuard } from './guards/jwt.guard';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'login:token', component: LoginComponent},
  {
    path: '',
    component: AdminLayoutComponent,
    loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
    canActivate: [JwtGuard],
  },
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
