import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DashboardHttpInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private router: Router,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`).set('x-api-key', window['API_KEY'] ?? ''),
      url: req.url,
    });
    return next.handle(authReq).pipe(
      catchError((err => {
        const message = err.error?.message || err.message;
        this.toastr.error(`<span class="now-ui-icons ui-1_bell-53"></span> ${message}`, '', {
          timeOut: 1000 * 5,
          enableHtml: true,
          closeButton: true,
          toastClass: 'alert alert-danger alert-with-icon',
        });
        if (err.error?.statusCode === 401) {
          this.router.navigateByUrl('/login');
        }
        return throwError(err);
      })),
    );
  }
}
